<template>
  <!-- modal :  다운로드 start -->
  <div
    id="download"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="download"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            다운로드
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <div>
              <h5 v-if="taskFileNm">
                <i class="fas fa-arrow-down download_icon mr-10px" />
                파일명 : <span class="font-weight-normal">
                  {{ taskFileNm }}
                </span>
              </h5>

              <div class="border bg-lightgray mt-10px py-25px">
                <!-- 다운로드 완료 start -->
                <template v-if="taskStatus === 'C'">
                  <div class="text-center ft18 text-primary">
                    다운로드 완료
                  </div><!-- .text-primary 추가, text 변경 -->
                  <div
                    class="download_progressbar success m-auto mt-20px"
                    title="다운로드 완료"
                  /><!-- .success 추가, title 수정 -->
                </template>
                <!--// 다운로드 완료 end -->
                <template v-else>
                  <!-- 다운로드 중 start -->
                  <div class="text-center ft18">
                    다운로드 중...
                  </div>
                  <div
                    class="download_progressbar m-auto mt-20px"
                    title="다운로드 중"
                  />
                  <!--// 다운로드 중 end -->
                </template>
              </div>
            </div>
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <!--
					다운로드 중 : .btn-primary / '취소'
					다운로드 완료 : .btn-secondary / '확인'
					-->
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal :  다운로드 end -->
</template>
<script>
import ExcelService from '@/services/excel/ExcelService';
import axios from 'axios';

export default {
  name: 'ExcelDown',
  props: {
    id: {
      type: Number,
      default: null,
      required: false
    },
    apiUrl: {
      type: String,
      default: null,
      required: false
    },
    apiType: {
      type: String,
      default: null,
      required: false
    },
    searchParam: {
      type: Object,
      default: null,
      required: false
    },
    saveUserData: {
      type: Object,
      default: null,
      required: false
    },
    cdDiv: {
      type: String,
      default: null,
      required: false
    },
    type: {
      type: String,
      default: null,
      required: false
    },
    consRecId: {
      type: String,
      default: null,
      required: false
    },
    inteMbrId: {
      type: String,
      default: null,
      required: false
    },
  },
  data() {
    return {
      jobNotiTimeout: null,
      importFile: null,
      taskStatus: null,
      taskFileNm: null
    }
  },
  destroyed() {
    clearTimeout(this.jobNotiTimeout);
  },
  mounted(){
    this.fetchData();
  },
  methods:{
    fetchData(){
      let params = {
        regId: this.saveUserData.inteMbrId,
        type: this.apiType,
        cdDiv: this.cdDiv,
        consRecId: this.consRecId,
        inteMbrId: this.inteMbrId,
      }

      ExcelService[this.apiUrl](this.searchParam, params).then(response=>{
        const {data} = response.data;
        if(data.batchId){
          this.fetchJobNoti(data.batchId);
        }
      })
    },
    fetchJobNoti(batchId){
      ExcelService.commonJobNoti({'batchId':batchId}).then(response=>{
        const {data} = response.data;
        this.taskStatus = data.taskStatus;
        if(data.taskStatus && data.taskStatus == 'C'){
          this.fetchStartDown(data);
          clearTimeout(this.jobNotiTimeout);
        }else{
          clearTimeout(this.jobNotiTimeout);
          this.jobNotiTimeout = setTimeout(() => { this.fetchJobNoti(batchId); }, 1000);
        }
      })
    },
    fetchStartDown(data){
      axios({
        url: this.type === 'zip' ?
          this.$http.defaults.baseURL+'/api/common/zip/download'
          : this.$http.defaults.baseURL+'/api/common/excel/download',
        method: 'GET',
        responseType: 'blob', // Important
        params: {
          'batchId': data.batchId
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const contentDisposition = response.headers['content-disposition']; // 파일 이름
        let fileName = 'unknown';
        if (contentDisposition) {
          const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
          if (fileNameMatch)
            [ , fileName ] = fileNameMatch.split('=');
        }
        this.taskFileNm = decodeURIComponent(escape(fileName));
        if(this.taskFileNm){
          this.taskFileNm = this.taskFileNm.replace(/^\"|\"$/g,'');
        }
        link.href = url;
        link.setAttribute('download', this.taskFileNm ? this.taskFileNm : '엑셀다운로드.xlsx');
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch(error => {
        alert(error.message)
      });
    },
    close(){
      this.$emit("close");
    }
  }
}
</script>
