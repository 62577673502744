import http from '@/api/http';

export default {
  // 엑셀 다운로드 bat
  commonBatExcelDown(search=null,params={}) {
    return http.post('/api/common/batch/excel/download',search,{params:params});
  },
  // 프로젝트 엑셀 다운로드 bat
  projectExcelDown(search={},params={}) {
    return http.post('/api/eap/customer/project/batch/excel/download',search,{params:params});
  },
  // 협약센터정보 엑셀 다운로드 bat
  conConExcelDown(search=null,params={}) {
    return http.post('/api/consult-center/convention/batch/excel/mainDownload',search,{params:params});
  },
  // 협약센터정보 엑셀 업로드
  conConExcelUpload(form) {
    return http.post('/api/consult-center/convention/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 협약센터정보 엑셀 양식다운
  conConExcelSampleDown(search={},params={}) {
    return http.post('/api/consult-center/convention/excel/sampleDownload', search,{params:params});
  },
  // 사내상담센터정보 엑셀 다운로드 bat
  conComExcelDown(search=null,params={}) {
    return http.post('/api/consult-center/company/batch/excel/mainDownload',search,{params:params});
  },
  // 권역별추천센터정보 엑셀 다운로드 bat
  conRecExcelDown(search=null,params={}) {
    return http.post('/api/consult-center/recommendation/batch/excel/mainDownload',search,{params:params});
  },
  // 배정상담기록부 엑셀 다운로드 bat
  manageExcelDown(search={},params={}) {
    return http.post('/api/consult/manage/batch/excel/download',search,{params:params});
  },
  // 배정상담기록부 > 상담기록부 > 만족도 결과 엑셀 다운로드 bat
  setManageExcelDown(search={},params={}) {
    return http.post('/api/consult/sat-manage/batch/excel/download',search,{params:params});
  },
  // 위기사례관리 엑셀 다운로드 bat
  crisisExcelDown(search={},params={}) {
    return http.post('/api/consult/crisis/batch/excel/download',search,{params:params});
  },
  // 후기관리 엑셀 다운로드 bat
  reviewExcelDown(search={},params={}) {
    return http.post('/api/consult/review/batch/excel/download',search,{params:params});
  },
  // 상담축어록관리 엑셀 다운로드 bat
  verbaMainExcelDown(search={},params={}) {
    return http.post('/api/verba/batch/excel/MainDownload',search,{params:params});
  },
  // 상담분석결과 엑셀 다운로드 bat
  verbaAnalExcelDown(search={},params={}) {
    return http.post('/api/verba/batch/excel/AnalDownload',search,{params:params});
  },
  // 표준 코드 관리 엑셀 다운로드 bat
  codeMngExcelDown(search={},params={}) {
    return http.post('/api/code/batch/excel/download', search,{params:params});
  },
  // 표준 코드 관리 - 위기감지 엑셀 다운로드 bat
  codeCrisisExcelDown(search={},params={}) {
    return http.post('/api/code/crisis/batch/excel/download', search,{params:params});
  },
  // 표준 코드 관리 - 위기감지 엑셀 업로드
  codeCrisisExcelUpload(form) {
    return http.post('/api/code/crisis/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 표준 코드 관리 - 위기감지 엑셀 다운로드 Form
  codeCrisisFormDown(search={},params={}) {
    return http.post('/api/code/crisis/excel/sampleDownload', search,{params:params});
  },
  // 표준 코드 관리 - (B2C) 금칙어 엑셀 다운로드 bat
  banWordExcelDown(search={},params={}) {
    return http.post('/api/code/ban/batch/excel/download', search,{params:params});
  },
  // 고객사 코드 관리 - 직군/직급 코드 다운로드 bat
  codeCusJobExcelDown(search={},params={}) {
    return http.post('/api/customerCode/batch/excel/jobDownload', search,{params:params});
  },
  // 고객사 코드 관리 - 직군/직급 코드 다운로드 Form
  codeCusJobFormDown(search={}, params={}) {
    return http.post('/api/customerCode/excel/sampleJobDownload', {},{params:params});
  },
  // 고객사 코드 관리 - 직군/직급 코드 업로드
  codeCusJobExcelUpload(form) {
    return http.post('/api/customerCode/excel/jobUpload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },

  // 고객사 코드 관리 - 부서정보 코드 다운로드 bat
  codeCusDeptExcelDown(search={},params={}) {
    return http.post('/api/customerCode/batch/excel/deptDownload', search,{params:params});
  },
  // 고객사 코드 관리 - 부서정보 코드 다운로드 Form
  codeCusDeptFormDown(search={}, params={}) {
    return http.post('/api/customerCode/excel/sampleDeptDownload', {},{params:params});
  },
  // 고객사 코드 관리 - 부서정보 코드 업로드
  codeCusDeptExcelUpload(form) {
    return http.post('/api/customerCode/excel/deptUpload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },


  // EAP 설정 > EAP 고객 > 회원 정보 목록 엑셀 다운로드 bat
  eapUserExcelDown(search={},params={}) {
    return http.post('/api/eap/user/batch/excel/download', search,{params:params});
  },
  // eap 고객 > 회원 정보 업로드
  eapUserExcelUpload(form) {
    return http.post('/api/eap/user/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // eap 고객 > 회원 정보 양식다운
  eapUserExcelSampleDown(search={},params={}) {
    return http.post('/api/eap/user/excel/sampleDownload', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 고객사 정보 목록 엑셀 다운로드 bat
  eapCusExcelDown(search={},params={}) {
    return http.post('/api/eap/customer/batch/excel/download', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 고객사 사번연동 양식 다운로드(샘플다운)
  eapCusExcelSampleEmDown(search={},params={}) {
    return http.post('/api/eap/customer/excel/sampleEmDownload', {}, {
      params:params,
      headers: {
      'Content-Type': 'application/json'
      }
    });
  },
  // EAP 설정 > EAP 고객 > 고객사 가족정보연동 양식 다운로드(샘플다운)
  eapCusExcelSampleFamDown(search={},params={}) {
    return http.post('/api/eap/customer/excel/sampleFamilyDownload', {}, {
      params:params,
      headers: {
      'Content-Type': 'application/json'
      }
    });
  },

  // EAP 설정 > EAP 고객 > 홈페이지 관리 엑셀 다운로드 bat
  homeExcelDown(search={},params={}) {
    return http.post('/api/eap/customer/homepage/batch/excel/download', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 홈페이지 관리 > 힐링 콘텐츠 설정
  homeHealingContentsExcelDown(search={},params={}) {
    return http.post('/api/eap/customer/homepage/batch/excel/headlingContentsDownload', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 홈페이지 관리 > 팝업창
  homePopExcelDown(search={},params={}) {
    return http.post('/api/eap/customer/homepage/batch/excel/popupDownload', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 힐링 프로그램 관리
  healingExcelDown(search={},params={}) {
    return http.post('/api/healing/batch/excel/download', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 힐링 프로그램 관리 > 신청자 명단
  healingApplExcelDown(search={},params={}) {
    return http.post('/api/healing/batch/excel/applDownload', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 청구 관리
  eapMngChaExcelDown(search={},params={}) {
    return http.post('/api/eap/mng/cha/batch/excel/download', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 청구 관리 Tab3 > 회기보고서 목록
  eapMngChaBilReportDown(search={},params={}) {
    return http.post('/api/eap/mng/cha/batch/excel/reportDownload', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 청구 관리 Tab3 > 청구서
  eapMngChaBilFileDown(search={},params={}) {
    return http.post('/api/eap/mng/cha/batch/excel/download', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 청구 관리 Tab3 > 실적 증빙 서류
  eapMngChaBilZipFileDown(search={},params={}) {
    return http.post('/api/eap/mng/cha/zipFileDownload', search,{params:params});
  },
  // EAP 설정 > EAP 관리 > 지급 관리
  eapMngPayExcelDown(search={},params={}) {
    return http.post('/api/eap/mng/pay/batch/excel/download', search,{params:params});
  },

  // EAP 설정 > EAP 관리 > 지급 관리 Tab3 > 지급서
  eapMngPayBilFileDown(search={},params={}) {
    return http.post('/api/eap/mng/pay/batch/excel/download', search,{params:params});
  },

  // EAP 설정 > EAP 통계 > 전문가 자격증 > 전문가 현황
  eapStaConExplicExcelDown(search={},params={}) {
    return http.post('/api/statistics/center-expert/batch/excel/download', search,{params:params});
  },

  // EAP 설정 > EAP 통계 > 조건별 전문가 현황 > 전문가 현황
  eapStaConExpExcelDown(search={},params={}) {
    return http.post('/api/statistics/center-expert/batch/excel/expertInfoDownload', search,{params:params});
  },

  // EAP 설정 > EAP 통계 > 위기관리 현황 > 위기사례 명단
  eapStaEmeListExcelDown(search={},params={}) {
    return http.post('/api/statistics/status/batch/excel/download', search,{params:params});
  },

  // EAP 설정 > EAP 고객 > 고객센터 > 공지사항
  eapCusNotiExcelDown(search={},params={}) {
    return http.post('/api/eap/cus/cs/batch/excel/notiDownload', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 고객센터 > FAQ
  eapCusFaqExcelDown(search={},params={}) {
    return http.post('/api/eap/cus/cs/batch/excel/faqDownload', search,{params:params});
  },
  // EAP 설정 > EAP 고객 > 고객센터 > 1:1문의
  eapCusOtoExcelDown(search={},params={}) {
    return http.post('/api/eap/cus/cs/batch/excel/otoDownload', search,{params:params});
  },
  // B2C 마인드톡 > 회원 정보
  btocUserExcelDown(search={},params={}) {
    return http.post('/api/btoc/user/batch/excel/download', search,{params:params});
  },
  // B2C 마인드톡 > 쿠폰 관리
  couExcelDown(search={},params={}) {
    return http.post('/api/coupon/batch/excel/download', search,{params:params});
  },

  // B2C 마인드톡 > 쿠폰 관리 > 쿠폰 및 쿠폰 코드 사용/발급 현황
  couUsageExcelDown(search={},params={}) {
    return http.post('/api/coupon/batch/excel/usageDownload', search,{params:params});
  },

  // B2C 마인드톡 > 고객센터 > 공지사항
  btocCsExcelDown(search={},params={}) {
    return http.post('/api/btoc/cs/batch/excel/notiDownload', search,{params:params});
  },
  // B2C 마인드톡 > 고객센터 > FAQ
  btocCsFaqExcelDown(search={},params={}) {
    return http.post('/api/btoc/cs/batch/excel/faqDownload', search,{params:params});
  },
  // B2C 마인드톡 > 고객센터 > 1:1문의
  btocCsOtoExcelDown(search={},params={}) {
    return http.post('/api/btoc/cs/batch/excel/otoDownload', search,{params:params});
  },
  // B2C 마인드톡 > 이용권 관리
  btocVouExcelDown(search={},params={}) {
    return http.post('/api/btoc/vou/batch/excel/download', search,{params:params});
  },
  // B2C 마인드톡 > 결제 관리
  btocPayExcelDown(search={},params={}) {
    return http.post('/api/btoc/pay/batch/excel/download', search,{params:params});
  },
  // B2C 마인드톡 > 후기 관리
  btocRevExcelDown(search={},params={}) {
    return http.post('/api/btoc/review/batch/excel/download', search,{params:params});
  },
  // B2C 마인드톡 > 상담료 지급 관리 > 지급 전
  btocPcfExcelDown(search={},params={}) {
    return http.post('/api/btoc/pcf/batch/excel/download', search,{params:params});
  },
  // B2C 마인드톡 > 상담료 지급 관리 > 지급 전
  btocPcfPayBilFileDown(search={},params={}) {
    return http.post('/api/btoc/pcf/batch/excel/download', search,{params:params});
  },
  // 전문가 관리 > 외부 전문가 정보
  expertInfoExcelDown(search={},params={}) {
    return http.post('/api/expert/info/batch/excel/download', search,{params:params});
  },
  // 전문가 관리 > 외부 전문가 정보 - Form
  expertInfoFormExcelDown(search={}, params={}) {
    return http.post('/api/expert/info/excel/sampleDownload', {},{params:params});
  },
  // 전문가 관리 > 외부 전문가 정보 - 업로드
  expertInfoExcelUpload(form) {
    return http.post('/api/expert/info/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 전문가 관리 > 사내 전문가 정보
  expertComInfoExcelDown(search={},params={}) {
    return http.post('/api/expert/cominfo/batch/excel/download', search,{params:params});
  },
  // 전문가 관리 > 사내 전문가 정보 - Form
  expertComInfoFormExcelDown(search={}, params={}) {
    return http.post('/api/expert/cominfo/excel/sampleDownload', {},{params:params});
  },
  // 전문가 관리 > 사내 전문가 정보 - 업로드
  expertComInfoExcelUpload(form) {
    return http.post('/api/expert/cominfo/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 전문가 관리 > 채용 관리 목록 다운로드
  expEmpExcelDown(search={},params={}) {
    return http.post('/api/professional/employ/batch/excel/download', search,{params:params});
  },
  // 전문가 관리 > 고객센터 > 공지사항
  expertCsNotiExcelDown(search={},params={}) {
    return http.post('/api/expert/cs/batch/excel/notiDownload', search,{params:params});
  },
  // 전문가 관리 > 고객센터 > 팝업창
  expertCsPopExcelDown(search={},params={}) {
    return http.post('/api/expert/cs/batch/excel/popDownload', search,{params:params});
  },
  // 전문가 관리 > 고객센터 > FAQ
  expertCsFaqExcelDown(search={},params={}) {
    return http.post('/api/expert/cs/batch/excel/faqDownload', search,{params:params});
  },
  // 전문가 관리 > 고객센터 > 1:1문의
  expertCsOtoExcelDown(search={},params={}) {
    return http.post('/api/expert/cs/batch/excel/otoDownload', search,{params:params});
  },
  // 온라인 심리검사 관리 > 개별 검사 관리
  onIndExcelDown(search={},params={}) {
    return http.post('/api/online/individual-test/batch/excel/download', search,{params:params});
  },
  // 온라인 심리검사 관리 > 통합 검사 관리
  onIntExcelDown(search={},params={}) {
    return http.post('/api/online/integrate-test/batch/excel/download', search,{params:params});
  },
  // 슈퍼 관리자 > 운영자 관리
  superMngExcelDown(search={},params={}) {
    return http.post('/api/super-admin/admin/batch/excel/download', search,{params:params});
  },

  // 엑셀 업로드 bat
  commonBatExcelUpload(form=null) {
    return http.post('/api/common/batch/excel/upload',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 배치 상태 확인
  commonJobNoti(params={}) {
    return http.get('/api/common/jobNoti',{params:params});
  },
  // commonJobNoti 결과 taskStatus : "C" 일경우 다운로드 실행
  commonExcelDown(params={}) {
    return http.get('/api/common/excel/download',{params:params},{responseType: 'blob'});
  },
}
