import http from '@/api/http';

export default {
    loginTest(params={}) {
      // 조회
      return http.get('/springSession/test');
    },
    clogin(params) {
      return http.post('/api/chat/clogin', params);
    },
    logout(params={}) {
      // 로그아웃
      return http.get('/api/logout');
    },
    getMyMenuList(params={}) {
      // 메뉴리스트
      return http.get('/api/common/menuList');
    },
    testRead(params={}) {
      // 읽기
      return http.get('/api/auth/r');
    },
    testWrite(params={}) {
      // 쓰기
      return http.post('/api/auth/w');
    },
    testCreate(params={}) {
      // 생성
      return http.post('/api/auth/c');
    },
    testDelete(params={}) {
      // 삭제
      return http.get('/api/auth/d');
    },
    testRead2(params={}) {
      // 읽기
      return http.get('/api/test2/r');
    },
    testWrite2(params={}) {
      // 쓰기
      return http.post('/api/test2/w');
    },
    testDelete2(params={}) {
      // 삭제
      return http.get('/api/test2/d');
    },
}