// Attach an event listener to an element
export const eventOn = (el, eventName, handler) => {
    if (el && el.addEventListener) {
      el.addEventListener(eventName, handler);
    }
  };
  
  // Remove an event listener from an element
  export const eventOff = (el, eventName, handler) => {
    if (el && el.removeEventListener) {
      el.removeEventListener(eventName, handler);
    }
  };
  