import "es6-promise/auto";
import "babel-polyfill";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $http from './api/http';
import store from "./store";
import vSelect from "vue-select";
import XLSX from "xlsx";
import {Treeselect,treeselectMixin} from "@riophae/vue-treeselect";
import VueVirtualSelector from "vue-virtual-selector";
import vmodal from 'vue-js-modal';

import DatePicker from "vue-bootstrap-datetimepicker";
import VueSignature from 'vue-signature-pad';
import FormUl from "./components/excel/FormUl";
import ExcelDown from "./components/excel/ExcelDown";
import ExcelUl from "./components/excel/ExcelUl";
import CtxMenu from "./components/lib/ctx-menu-vue";
import UiDialog from "./components/ui/UiDialog";
import JwtService from "@/common/jwt.service";
import localData from '@/api/localData';
import vueCookies from "vue-cookies";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min'
import 'tooltip-plugin/dist/tooltip.min.css';
import 'tooltip-plugin/dist/tooltip.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import "expose-loader?$!expose-loader?jQuery!jquery";
import "jquery-ui-dist/jquery-ui.min";
import "bootstrap";

import VueI18n from 'vue-i18n';
import i18n from "./i18n";
import "./assets/css/template.css";
import "./assets/css/layout.css";
import "./assets/css/webfont.css";
import "./assets/css/color_palette.css"
import "./assets/css/pdf-print.css"

import "./assets/css/chatbot_template.css";
import "./assets/css/chatbot_theme_basic.css";
import "vue-select/dist/vue-select.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "tui-pagination/dist/tui-pagination.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import naver from 'vue-naver-maps';
import "./assets/js/common";
var browse = navigator.userAgent.toLowerCase();
if( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (browse.indexOf("msie") != -1)) {

}else{
  import('vue-flatpickr-component')
  .then((something) => {
    if(something && something.default){
      Vue.component("flat-pickr", something.default);
    }
  });
  import('flatpickr/dist/flatpickr.css');
  import('flatpickr/dist/l10n/ko.js');
}

Vue.use(naver, {
  clientID: 'xjtlmctf4f',
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules:'geocoder' // 서브모듈 (선택)
});

treeselectMixin.props.noOptionsText.default = '선택할 항목이 없습니다.';
treeselectMixin.props.noResultsText.default = '입력하신 항목이 없습니다.';
treeselectMixin.props.clearAllText.default = '선택 항목 삭제';
treeselectMixin.props.clearValueText.default = '선택 항목 삭제';
treeselectMixin.props.searchPromptText.default = '검색하려면 입력...';
treeselectMixin.props.loadingText.default = '로딩중...';
treeselectMixin.props.limitText.default = function limitTextDefault(count) {
  return "총 ".concat(count, "개 선택");
};
Vue.component("treeselect", Treeselect);
Vue.component("date-picker", DatePicker);
Vue.use(VueVirtualSelector);
Vue.use(vueCookies);
Vue.$cookies.config("7d");

Vue.use(VueSignature);
Vue.use(vmodal, { dialog: true, dynamic: true, dynamicDefaults: { clickToClose: false },  injectModalsContainer: true })
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', ''),
  },
});

/*UiButton*/
import UiButton from "./components/ui/UiButton";
Vue.component("ui-button", UiButton);

/*Authorize*/
import $UtilsAuthorize from "./utils/Authorize";
import {initApp} from "@/utils/FirebaseService";
import {pushHandler} from "@/utils/PushHandler";
Vue.prototype.$UtilsAuthorize = $UtilsAuthorize;

Vue.component("v-select", vSelect);
Vue.component("XLSX", XLSX);
Vue.component("ctx-menu-vue", CtxMenu);

Vue.config.productionTip = false;
Vue.prototype.$FormUl = FormUl;
Vue.prototype.$ExcelDown = ExcelDown;
Vue.prototype.$ExcelUl = ExcelUl;
Vue.prototype.$UiDialog = UiDialog;
Vue.prototype.$http = $http;
router.beforeEach((to, from, next) => {
  const {authorize} = to.meta;
  const token = JwtService.getToken();
  const userId = localData.getUserId();
  if(authorize){
    if (!userId || userId == null) {
      // 로그인 화면으로 이동
      //store.commit("delToken");
      localData.removeUserId();
      localData.removeSaveUserData();
      return next({ path: "/web/login", query: { returnUrl: to.path } });
    }
  }

  next();
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// 알림 권한 요청
askForNPerm();
function askForNPerm() {
  Notification.requestPermission(function(result) {
    console.log("User choice", result);
    if (result !== "granted") {
      console.log("No notification permission granted!");
    } else {
      // configurePushSub();// Write your custom function that pushes your message
    }
  });
}
//파이어베이스 초기화
initApp(pushHandler);



