//푸시 메시지 브라우저 켜져있을시
export const pushHandler=(payload)=>{
  try{
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
      console.log("notifications call");
      // If it's okay let's create a notification
      var notification = new Notification(notificationTitle,notificationOptions);
      notification.onclick = function(event) {
        event.preventDefault();
        window.open(payload.notification.click_action , '_blank');
        notification.close();
      }
    }
  }catch (e){
    console.log(e);
  }
}
