import http from '@/api/http';

export default {
  authCreate(params={}) {
    // 역할 등록
    return http.post('/api/super-admin/auth/create', params);
  },
  authUpdate(params={}) {
    // 역할 수정
    return http.put('/api/super-admin/auth/update', params);
  },
  authNameCheck(roleNm={}) {
    // 역할명 체크
    return http.get('/api/super-admin/auth/roleNameCheck?roleNm='+roleNm);
  },
  authRoleList(roleId={}) {
    // 역할명 상세조회 - 기본 역할 정보(권한) 가져오기
    // 메뉴 + 권한 목록.
    return http.get('/api/super-admin/auth/roleAuthList?roleId='+roleId);
  },
  authRoleExpList(roleId={}) {
    // 역할명 상세조회 - 기본 역할 정보(권한) 가져오기
    // 메뉴 + 권한 목록. - 전문가
    return http.get('/api/super-admin/auth/expRoleAuthList?roleId='+roleId);
  },
  // 권한 목록 조회
  authFindList(params={}) {
    return http.get('/api/super-admin/admin/roleList',{params:params});
  },
  authDelete(params={}) {
    // 역할 삭제
    return http.delete('/api/super-admin/auth/delete', params);
  },
  menuList() {
    // 메뉴 목록 - 관리자
    return http.get('/api/super-admin/auth/menuList');
  },
  menuExpList(params={}) {
    // 메뉴리스트 - 전문가
    return http.get('/api/super-admin/auth/expertMenuList');
  },
  updateAuth(params={}) {
    // 역할 권한 부여
    return http.put('/api/super-admin/auth/updateAuth', params);
  },
}
