<template>
  <!-- modal :  업로드 start -->
  <div
    id="upload"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="upload"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            업로드
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- 항목 : 첨부파일 start -->
            <div class="row h-40 type_flex align-items-center">
              <p class="w-70px pr-10">
                첨부파일
              </p>
              <div class="flex_item row form-group">
                <template v-if="importFile != null">
                  <input
                    v-model="importFile.name"
                    type="text"
                    class="pseudo_input"
                    readonly
                  >
                </template>
                <template v-else>
                  <input
                    type="text"
                    value="선택된 파일 없음"
                    class="pseudo_input"
                    readonly
                  >
                </template>
                <label for="inputTypeFile">
                  파일 선택
                </label>
                <input
                  id="inputTypeFile"
                  ref="importFile"
                  type="file"
                  class="form-control-file"
                  @change="previewFile"
                >
              </div>
            </div>
            <!--// 항목 : 첨부파일 end -->

            <!-- 항목 : 초기화 start -->
            <div
              v-if="resetYn === 'Y'"
              class="row pl-70px"
            >
              <div class="custom-control custom-checkbox">
                <input
                  id="customCheck1"
                  v-model="resetFile"
                  type="checkbox"
                  class="custom-control-input"
                  true-value="1"
                  false-value="-1"
                >
                <label
                  class="custom-control-label"
                  for="customCheck1"
                >
                  초기화
                </label>
              </div>
            </div>
            <!--// 항목 : 초기화 end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="!importFile||confirmDisabled"
            @click="saveData"
          >
            확인
          </button><!-- 파일 선택 후 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal :  업로드 end -->
</template>
<script>
import ExcelService from '@/services/excel/ExcelService';
import axios from 'axios';
import EventBus from '@/eventBus/eventBus.js';

export default {
  name: 'ExcelUl',
  props: {
    apiUrl: {
      type: String,
      default: null,
      required: false
    },
    id: {
      type: Number,
      default: null,
      required: false
    },
    resetYn: {
      type: String,
      default: null,
      required: false
    },
    saveUserData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      jobNotiTimeout: null,
      importFile: null,
      confirmDisabled:false,
      resetFile:-1
    }
  },
  destroyed() {
    clearTimeout(this.jobNotiTimeout);
  },
  mounted(){
    //this.fetchData();
  },
  methods:{
    previewFile() {
      if (0 < this.$refs.importFile.files.length) {
        this.importFile = this.$refs.importFile.files[0];
      } else {
        this.importFile = null;
      }
    },
    saveData(){
      this.confirmDisabled=true;
      let form = new FormData();
      form.append("multipartFile", this.importFile);
      form.append("regId", this.saveUserData.inteMbrId);
      form.append('excelResetYn', this.resetYn === 'Y' && this.resetFile == 1 ? 'Y' : 'N')
      ExcelService[this.apiUrl](form).then(response=>{
        const {data} = response.data;
        if(data && data.hasOwnProperty('insert') && data.insert === true){
          alert('업로드가 완료되었습니다.')
        }else{
          this.confirmDisabled=false;
          let msg = '업로드를 실패했습니다.';
          if(response.data.description){
            if(response.data.description.includes('zeroCustId')){
              let custNm = response.data.description.split(' ');
              custNm[1] && custNm[1] != 'null' ? msg = `존재하지않는 고객사명이 있습니다. (${custNm[1]})\n 파일 수정 후 다시 업로드 해주세요.` : msg = `존재하지않는 고객사명이 있습니다.\n 파일 수정 후 다시 업로드 해주세요.`;
              alert(msg);
            }else if(response.data.description.includes('multiCustId')){
              let custNm = response.data.description.split(' ');
              alert(`동일한 명칭의 고객사가 여러개 등록되어있어서 엑셀로 업로드 할 수 없습니다.
              \n${custNm[1]} 고객사를 제외하고 다시 업로드 해주세요.`);
            }else if(response.data.description.includes('not deptNm')){
              alert(`사번 연동 파일의 소속 정보값이 적어도 하나 이상이어야 등록됩니다.
              \n확인 후 다시 등록해주세요.`);
            }else if(response.data.description.includes('inOrderData')){
              alert(`소속 데이터를 LV.1 부터 순서대로 입력해 주세요.`);
            }else if(response.data.description.includes('not jobGrpNm')){
              alert(`직군 데이터를 입력해 주세요.`);
            }else if(response.data.description.includes('not posNm')){
              alert(`직급 데이터를 입력해 주세요.`);
            }else if(response.data.data.message.includes('Excel form not correct')){
              alert(`샘플 양식 파일이 아닙니다.`);
            }else{
              alert(response.data.description);
            }
          }else{
            alert(msg)
          }
        }
        EventBus.$emit('ExcelUpload',{});
        this.close();
      }).catch(err=>{
        this.confirmDisabled=false;
      });
    },
    fetchJobNoti(batchId){
      ExcelService.commonJobNoti({'batchId':batchId}).then(response=>{
        const {data} = response.data;
        if(data.taskStatus && data.taskStatus == 'C'){
          alert('업로드가 완료되었습니다.')
          clearTimeout(this.jobNotiTimeout);
          this.close();
        }else{
          clearTimeout(this.jobNotiTimeout);
          this.jobNotiTimeout = setTimeout(() => { this.fetchJobNoti(batchId); }, 1000);
        }
      })
    },
    close(){
      this.$emit("close");
    }
  }
}
</script>
