<template>
  <span
    v-if="type === 'icon'"
    :title="titleText"
  >
    <button
      type="button"
      :class="iconClasses"
      :title="titleText"
      @click.prevent.stop="onClick"
    />
  </span>
  <button
    v-else
    type="button"
    :class="classes"
    :disabled="disabled"
    :title="titleText"
    @click.prevent.stop="onClick"
  >
    <i
      v-if="icon"
      :class="icon"
    />{{ text ? text : '' }}
  </button>
</template>
<script>
export default {
  name: 'UiButton',
  props: {
    text: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    buttonClass: {
      type: String,
      default: null
    },
    authorize: {
      type: String,
      default: null
    },
    userAuthorize: {
      type: String,
      default: null
    },
    userAuthorizeEm: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      titleText: '',
      classValue: '',
      click: false,
      disabled: false,
      auth: [
        {
          auth: 'M',
          authText: '문자메시지',
          authNm: 'SMS'
        },
        {
          auth: 'E',
          authText: '이메일',
          authNm: 'EMAIL'
        },
        {
          auth: 'D',
          authText: '삭제',
          dep: 4,
        },
        {
          auth: 'C',
          authText: '추가',
          dep: 3,
        },
        {
          auth: 'U',
          authText: '수정',
          dep: 2,
        },
        {
          auth: 'R',
          authText: '조회',
          dep: 1,
        },
      ],
    }
  },
  computed: {
    classes() {
      let classValue = ""
      if ( this.type != null ) {
        classValue = `btn btn-${this.type}`;
      }
      if ( this.buttonClass ) {
        classValue = classValue+' '+ this.buttonClass;
      }
      return classValue;
    },
    iconClasses() {
      let classValue = ""
      if ( this.icon != null ) {
        classValue = this.icon;
      }
      if ( this.disabled ) {
        classValue = classValue+' disabled';
      }
      return classValue;
    },
  },
  mounted() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      /*
      - 1. 메뉴 권한
        - 실행(X) > 삭제(D) > 추가(C)  > 수정(U) > 조회(R)
        - 2022-04 기준 실행(X) 사용 안 함. 최상위 권한은 삭제(D)
      - 2. 문자메시지/이메일 권한
        - 문자메시지/이메일 권한은 Hierarchy에 속하지 않음. 별도 처리
        - 문제미시지: M / 이메일: E
     */
      if(this.$route.params.saveUserData.roleId == '17') {
        this.click = true;
        this.titleText = this.title;

      }else {
        if(this.auth.map(n=>n.auth === this.authorize)) {
          if((this.authorize === 'E' || this.authorize === 'M')) {
            if(
              (this.authorize === 'E' && this.userAuthorizeEm.EMAIL)
            || (this.authorize === 'M' && this.userAuthorizeEm.SMS) ) {
              this.click = true;
              this.titleText = this.title;
            }else {
              this.click = false;
              this.titleText = this.auth.filter(n=>n.auth === this.authorize).map(n=>n.authText)+ ' 권한이 없습니다.';
              this.disabled = true;
            }
          }else {
            let authorizeLv = 0;
            let userAuthorizeLv = 0;
            this.auth.filter(n=>n.dep).forEach((item, index) => {
              if(this.authorize === item.auth) {
                authorizeLv = item.dep;
              }
              if (this.userAuthorize === item.auth) {
                userAuthorizeLv = item.dep;
              }
            });
            if (userAuthorizeLv < authorizeLv) {
              this.auth.filter(n=>n.dep).forEach((item, index) => {
                if (this.authorize === item.auth) {
                  this.titleText =  item.authText +' 권한이 없습니다.';
                  this.disabled = true;
                }
              })
              this.click = false;

            } else {
              this.click = true;
              this.titleText = this.title;
            }
          }
        }
      }
    },
    onClick(e) {
     if(this.click) {
        this.$emit('click', e);
      }
    }
  }
}
</script>
<style scoped>
.auth-disabled {
  cursor:pointer  !important;
}
</style>
