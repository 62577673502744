// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import http from "@/api/http";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBesDuOsGJxDyZ6ApXF04cJo-HQJXpSrv8",
  authDomain: "webpush-5e70b.firebaseapp.com",
  projectId: "webpush-5e70b",
  storageBucket: "webpush-5e70b.appspot.com",
  messagingSenderId: "507789829264",
  appId: "1:507789829264:web:3a152e67b17b9e3e106415",
  measurementId: "G-HNX7YZJ5Z9"
};

let app;
let messaging;

export function initApp(handler){
  app=initializeApp(firebaseConfig);
  messaging=getMessaging(app);
  onMessage(messaging, handler);
}


export function initToken() {
  try {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', {type: "module"}).then(
      (serviceWorkerRegistration) => {
        console.log(serviceWorkerRegistration);
        getToken(messaging, {
          serviceWorkerRegistration,
          vapidKey: "BFOypiC5e6AfftKpPiqMx_Cnv8qqLcgL-PpfIHU4LcKQeModUu91RoZNINR_LwK3z_uw0Bs_aKV1GfUk5LN8V10"
        }).then((currentToken) => {
          if (currentToken) {
            http.post("/api/pushToken/update", {pushToken: currentToken});
            console.log(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      });
  } catch (e) {
    console.log(e);
  }
}
/*loginTp SysTp 10:관리도구 20:전문가도구 30:B2C사용자 40:B2B사용자*/
export function deleteToken(userId,sysTp) {
  try {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', {type: "module"}).then(
      (serviceWorkerRegistration) => {
        console.log(serviceWorkerRegistration);
        getToken(messaging, {
          serviceWorkerRegistration,
          vapidKey: "BFOypiC5e6AfftKpPiqMx_Cnv8qqLcgL-PpfIHU4LcKQeModUu91RoZNINR_LwK3z_uw0Bs_aKV1GfUk5LN8V10"
        }).then((currentToken) => {
          if (currentToken) {
            http.post("/api/pushToken/logout", {pushToken: currentToken,userId:userId,sysTp:sysTp});
            console.log(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      });
  } catch (e) {
    console.log(e);
  }
}
