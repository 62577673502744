
export const localData = {
  getUserMenuAuth(routeName) {
    let main = ['EapCusCs', 'ExpertCs', 'EapStaCon', 'EapMngCha', 'EapMngPay',
      'BtocHome', 'CodeCus', 'BtocCs', 'BtocPcf','AiAna', 'BtocSta'];
    let sub = ['EapCusCsFaq', 'EapCusCsOto', 'ExpertCsPopup', 'ExpertCsFaq', 'ExpertCsOto', 'EapStaConMap', 'EapStaConExpLic',
      'EapStaConPart','EapStaConMon', 'EapStaConEach', 'EapStaConExp', 'EapMngChaAft', 'EapMngChaBil', 'EapMngPayAft',
      'EapMngPayBil','EapMngPayMail', 'BtocHomeTag', 'BtocHomeAi', 'BtocHomeMen', 'BtocHomeHeaPro', 'BtocHomeHeaCon',
      'BtocHomeSat', 'BtocHomeBan', 'CodeCusDepart', 'BtocCsFaq', 'BtocCsOto', 'BtocPcfAft', 'BtocPcfBil','AiAnaRes', 'BtocStaSa'];

    if(sub.filter(n=>n === routeName).length > 0) {
      main.forEach((item, index) => {
        if(routeName.indexOf(item) !== -1) {
          routeName = item;
        }
      })
    }

    let result = false;
    let saveMenuData = this.getSaveMenuData();
    if(saveMenuData) {
      saveMenuData.forEach((item, index) => {
        if(item.menuView === routeName) {
          result = true;
          return true;
        }
      })
    }
    if(routeName === 'Login') {
      result = true;
    }
    return result;
  },
  setUserId(userId){
    localStorage.setItem('dqMindUserId', JSON.stringify(userId));
  },
  getUserId(){
    let userId = localStorage.getItem('dqMindUserId');
    if(userId) {
      try{
        userId = JSON.parse(userId);
        return userId;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeUserId() {
    localStorage.removeItem('dqMindUserId');
  },
  setInteMbrId(userId){
    localStorage.setItem('dqMindInteMbrId', JSON.stringify(userId));
  },
  getInteMbrId(){
    let userId = localStorage.getItem('dqMindInteMbrId');
    if(userId) {
      try{
        userId = JSON.parse(userId);
        return userId;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeInteMbrId() {
    localStorage.removeItem('dqMindInteMbrId');
  },
  setSaveUserData(userData){
    localStorage.setItem('mindonUserData', JSON.stringify(userData));
  },
  setSaveMenuData(menuData){
    localStorage.setItem('mindonMenuData', JSON.stringify(menuData));
  },
  getSaveUserData(){
    let userData = localStorage.getItem('mindonUserData');
    if(userData) {
      try{
        userData = JSON.parse(userData);
        return userData;
      } catch {
        return null;
      }
    }
    return null;
  },
  getSaveMenuData(){
    let menuData = localStorage.getItem('mindonMenuData');
    if(menuData) {
      try{
        menuData = JSON.parse(menuData);
        return menuData;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeSaveUserData() {
    localStorage.removeItem('mindonUserData');
  },
  removeSaveMenuData() {
    localStorage.removeItem('mindonMenuData');
  },
}

export default localData;
