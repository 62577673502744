<template>
  <div id="login_wrapper">
    <div class="login_area_wrap">
      <div class="login_area">
        <h1>
          mind-on
        </h1>
        <div>
          <form
            id="form_login"
            @submit.prevent="login()"
          >
            <div class="login_input">
              <input
                v-model="user"
                type="text"
                placeholder="ID"
              >
              <span class="login_icon01 fas fa-user-tag" />
              <input
                v-model="password"
                type="password"
                placeholder="Password"
              >
              <span class="login_icon02 fas fa-key" />
            </div>
            <button
              type="submit"
              class="login_btn btn-secondary"
              data-toggle="modal"
              data-target="#pass_change"
            >
              Login
            </button>
          </form>
          <div class="id_save">
            <div class="custom-control">
              <input
                id="id_save"
                v-model="idSave"
                type="checkbox"
                class="custom-control-input"
                true-value="1"
                false-value="-1"
              >
              <label
                class="custom-control-label"
                for="id_save"
              >
                아이디 저장하기
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="login_footer">
        Copyright © <strong>mind-on</strong>. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN } from "@/store/actions.type";
import LoginService from '@/services/LoginService';
import EventBus from '@/eventBus/eventBus.js';
import localData from '../api/localData';
import SuperMngAuth from "@/services/super/SuperMngAuth";
import {initToken} from "@/utils/FirebaseService";

export default {
  name: 'Login',
  data() {
    return {
      loginSuccess: false,
      loginError: false,
      user: this.$cookies.get("mindId") ? this.$cookies.get("mindId") : '',
      password: '',
      error: false,
      userType:'10',
      idSave: '-1',
    }
  },
  mounted(){
    document.title = 'EAP 관리도구';
  },
  methods: {
    async login() {
      let form = new FormData();
      form.append('username', this.user);
      form.append('password',this.password);
      form.append('type',this.userType);
      const params = {
        'mbrId': this.user,
        'pwd': this.password,
        'loginTp': this.userType
      };
      this.$http.post('/api/login', params).then(response => {
        if(response){
          const {data} = response;
          localData.setUserId(data.mbrId);
          localData.setInteMbrId(data.inteMbrId);
          localData.setSaveUserData(data);
          //푸시토큰 업데이트
          initToken();
          /*로그인한 유저 역할에 부여된 권한 리스트 저장*/
          if(data) {
            let roleId = data.roleId;
            SuperMngAuth.authRoleList(roleId).then(response => {
              const {data} = response.data;
              if(data && data[0]) {
                localData.setSaveMenuData(data[0].userAuths);
              }
            });
          }
          if(this.idSave == 1) {
            this.$cookies.remove('mindId');
            this.$cookies.set("mindId", this.user);
          }
          this.movePage(data);
        }else{
          alert("아이디 및 비밀번호를 다시 확인해 주세요.");
        }
      }).catch(err => {
        alert("아이디 및 비밀번호를 다시 확인해 주세요.");
      })
    },

    movePage(data){
      LoginService.getMyMenuList().then(response => {
        if(response){
          const {data} = response;
          if(data.data && data.data.length>0){
            this.$router.push({ path: '/web/dash' });
          }
          EventBus.$emit("login", data);
        }
      }).catch(error => {
      })
    }
  }
}

</script>
