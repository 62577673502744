import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth.module";
Vue.use(Vuex)

if (!localStorage.getItem('nav')) {
  localStorage.setItem('nav', null);
}

export default new Vuex.Store({
    modules: {
        auth
      },
    state: {
        count: 0,
        token: localStorage.getItem('test_token'),
        nav: localStorage.getItem('nav'),
    },
    mutations: {
      increment (state) {
        state.count++
      },
      getToken(state) {
        state.token = localStorage.getItem('test_token');
      },
      delToken (state) {
        localStorage.removeItem('test_token');
        state.token = '{}';
      },
      getNav(state) {
        state.nav = localStorage.getItem('nav');
      },
      delNav(state) {
        localStorage.removeItem('nav');
        state.nav = null;
      },
    }
  })