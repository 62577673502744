var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ off: _vm.nav.fold },attrs:{"id":"wrapper"}},[(_vm.type === 'layout')?[_c('div',{staticClass:"left_area"},[_c('div',{staticClass:"left_head"},[_c('a',{staticClass:"btn_navi",attrs:{"href":"#","title":"메뉴 접기"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.navFold($event)}}}),_c('h1',[_vm._v("mind-on")])]),_c('ul',{staticClass:"navi"},_vm._l((_vm.menuList),function(row){return _c('li',{key:row.menuView,on:{"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, row)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, row)}}},[(row.children.length>0)?[_c('a',{class:{
                on: (_vm.foldCheck && row.active && _vm.onCheck)
                  || (!_vm.foldCheck&& _vm.mouse.over && _vm.mouse.children !== false && _vm.mouse.children.indexOf(row.menuView) !== -1 )
                  || (!_vm.foldCheck&& _vm.mouse.over && _vm.mouse.children === false && row.menuView == _vm.mouse.name),
                open: row.active,
                on_view: row.active,
                'fas fa-building': row.menuView === 'Eap',//EAP 설정
                'fas fa-user-friends': row.menuView === 'Btoc',//B2C 마인드톡
                'fas fa-home': row.menuView === 'Con',//상담센터 관리
                'fas fa-user-tie': row.menuView === 'Expert',//전문가 관리
                'fas fa-heartbeat': row.menuView === 'Onl',//온라인 심리검사 관리
                'fas fa-robot': row.menuView === 'Ai',//AI 상담사
                'fas fa-code': row.menuView === 'Code',//코드 관리
                'fas fa-user-cog': row.menuView === 'Super',//슈퍼 관리자
              },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviShow($event, row)}}},[(_vm.foldCheck)?_c('span',[_vm._v(" "+_vm._s(row.menuName)+" ")]):(!_vm.foldCheck && _vm.mouse.over)?_c('span',[_vm._v(" "+_vm._s(row.menuName)+" ")]):_vm._e(),_c('i',{staticClass:"fas fa-angle-down"})]),_c('transition',{attrs:{"name":"slide"}},[((_vm.foldCheck && row.active) || (!_vm.foldCheck && _vm.mouse.over))?_c('div',{staticClass:"sub_wrap depth2_wrap",class:{ show: (_vm.foldCheck && row.active)}},[_c('ul',_vm._l((row.children),function(subMenu){return _c('li',{key:subMenu.menuView,on:{"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subMenu)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subMenu)}}},[(_vm.urlCheck(subMenu.url))?[_c('a',{attrs:{"href":"#","title":subMenu.menuName},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goUrl.apply(null, arguments)}}},[_vm._v(" "+_vm._s(subMenu.menuName)+" ")])]:(subMenu.children.length>0)?[_c('a',{class:{
                          open: subMenu.active,
                          on: subMenu.active && !_vm.foldCheck,
                          on_view: subMenu.active && !_vm.foldCheck,
                        },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviShow($event, subMenu)}}},[_vm._v(" "+_vm._s(subMenu.menuName)+" "),_c('span')]),_c('transition',{attrs:{"name":"slide"}},[((_vm.foldCheck && subMenu.active) || (!_vm.foldCheck&& _vm.mouse.over && _vm.mouse.children == subMenu.menuView && subMenu.menuView.indexOf(_vm.mouse.name) != -1 ))?_c('div',{staticClass:"sub_wrap depth3_wrap show",style:(subMenu.menuView === 'EapCus'
                              ? 'top:0px'
                              : '' || subMenu.menuView === 'EapMng'
                                ? 'top:33px'
                                : '' || subMenu.menuView === 'EapSta'
                                  ? 'top:66px'
                                  : '')},[_c('ul',_vm._l((subMenu.children),function(subChildren){return _c('li',{key:subChildren.menuView,on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviShow($event, subChildren)},"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subChildren)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subChildren)}}},[_c('router-link',{attrs:{"to":{ path: subChildren.url }}},[_vm._v(" "+_vm._s(subChildren.menuName)+" ")])],1)}),0)]):_vm._e()])]:[_c('router-link',{attrs:{"to":{ path: subMenu.url }},nativeOn:{"click":function($event){return _vm.naviShow($event, subMenu)}}},[_vm._v(" "+_vm._s(subMenu.menuName)+" ")])]],2)}),0)]):_vm._e()])]:[_c('router-link',{class:{
                on:(_vm.menu.on === row.menuView && _vm.foldCheck) ||!_vm.foldCheck && _vm.mouse.over&& row.menuView == _vm.mouse.name,
                on_view:(_vm.menu.currMenu === row.menuView && !_vm.foldCheck) ||!_vm.foldCheck && _vm.mouse.over&& row.menuView == _vm.mouse.name,
                'fas fa-tachometer-alt': row.menuView === 'Dash', // - 대시보드
              },attrs:{"to":{ path: row.url }},on:{"click":function($event){_vm.menu.currMenu = row.menuView}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.foldCheck ||!_vm.foldCheck && _vm.mouse.over&& row.menuView == _vm.mouse.name),expression:"foldCheck ||!foldCheck && mouse.over&& row.menuView == mouse.name"}]},[_vm._v(" "+_vm._s(row.menuName)+" ")])])]],2)}),0)]),_c('div',{staticClass:"right_area"},[_c('div',{staticClass:"right_head"},[_c('h2',{staticClass:"float-left"},[(_vm.menu.navDepth.length>0)?[_vm._l((_vm.menu.navDepth),function(depth){return [_vm._v(" "+_vm._s(depth)+" > ")]})]:_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.menu.navTitle))])],2),_c('ul',[_vm._m(0),_c('li',{staticClass:"user_info",attrs:{"id":"#user_info"}},[_c('span',[_vm._v("관리자 ("+_vm._s(_vm.dqMindUserId)+")")]),_vm._v("님 환영합니다. ")]),_c('li',{staticClass:"logout_btn"},[_c('button',{staticClass:"fas fa-sign-out-alt btn_type_gray",attrs:{"type":"button","title":"로그아웃"},on:{"click":_vm.logout}})])])]),_c('router-view',{attrs:{"save-user-data":_vm.saveUserData,"save-menu-data":_vm.saveMenuData}}),_vm._m(1)],1)]:[_c('router-view')]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"user_setup"},[_c('button',{staticClass:"fas fa-user",attrs:{"type":"button","title":"정보 수정","data-toggle":"modal","data-target":"#user_info"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dq_footer"},[_vm._v(" Copyright © "),_c('strong',[_vm._v("mind-on")]),_vm._v(". All rights reserved. ")])}]

export { render, staticRenderFns }